
<template>
  <div class="home">
    <el-container>
      <header-search  @custom-event="singOut" @SearchClick="SearchClick"></header-search>

      <el-main>
        <div class="main_top">
          <div class="card-wrap">
            <div
              class="card-context"
              v-for="(val, key) in item_content"
              :key="key"
              @click="sentMessage(val)"
            >
              <div class="dis_blo" style="float: left; margin-right: 5px" >
                  <img :src="val.img_url" alt="图片加载错误" srcset="" />
                
              </div>
              <div class="dis_blo" style="float: left">
                  <div>{{ val.name }}</div>
                <div>{{ val.topics }} topic</div>
              </div>
            </div>
            <!-- vue的遍历，目的是创造data.length个数的card -->
          </div>
        </div>
      </el-main>

      <el-footer>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import HeaderSearch from "@/components/HeaderSearch.vue";

export default {
  name: "Home",
  data() {
    return {
      value1: true,
      input3: "",
      currentDate: new Date(),
      activeIndex: "1",
      select: "Fetal",
      item_content: [],
      value: "",
      token: "",
    };
  },

  components: {
    HeaderSearch,
  },

  methods: {
    handleSelect() {
      console.log("11");
    },

    sentMessage(val){
     
      this.$router.push({ name: 'about', query: { category: val.name, num:1, lists: [val.name]} });

      const data = {
        "num": 1,
        "category": val.name,
        "lists": [val.name]
      }

      localStorage.setItem("data",JSON.stringify(data))

    },

    // 内容页面
    async content() {
      const that = this;
      this.axios.get("./index.json").then((res) => {
        that.item_content = res.data.data;
      });
    },

    // 判断跳往注册页
    getToken() {
      const token = localStorage.getItem('token');

      if(!token){
        this.$router.push('/login')
      }

    },

    singOut(){
      console.log("singOut");
      var that = this
      const token = localStorage.getItem('token');

      that.token = token
      const url = this.$baseUrl + '/logout'
      this.axios.post(url,{},  {
        headers: {
          'token': that.token
        }
      }).then((res) => {
        console.log(res.data);
        localStorage.removeItem('token')
        if(res.data.code == 200){
          alert("退出成功，请重新登录")
          this.$router.push('/login');
        }
      }).catch(error =>{
        alert(error)
      });
    },

    SearchClick(data){
      console.log("SearchClick", data);

      const url = this.$baseUrl + '/search'
      var that = this
      const token = localStorage.getItem('token');
      that.token = token
      const keyword = {
        "keyword": data
      }
      this.axios.post(url,{
        ...keyword
      },{
        headers: {
          'token': that.token
        }
      }).then((res) =>{
        console.log(res.data);
        if(res.data.content.length == 0){
          console.log("content.length == 0");
          alert("该搜索词没有内容，请重新输入")
          return;
        }
        console.log(typeof(keyword));
        var keyJson = JSON.stringify(data)
        console.log(keyJson);
        console.log(keyJson);
        this.$router.push({path:'/SearchDetail',query:{keyJson}});

      })
      
    }
  },
  created() {
    this.content();
    this.getToken()
  },
};
</script>

<style scoped>
@import "@/assets/css/home.css";
html {
  background-color: #1f1f1f;
}

</style>