<template>
  <div>
    <el-header>
      <div class="header-logo dis_blo">
        <a class="logo qa-logo" href="/"
          style="color: #eb6500; font-size: 45px;">影像服务中心</a>
      </div>
      <div class="dis_blo" style="float: right">
        <el-menu
          class="el-menu-demo"
          mode="horizontal"
        >
          <el-submenu index="1">
            <template slot="title"><router-link to="/Rest">Reset Password</router-link></template>
            <div class="title_menu">
             
            </div>
          </el-submenu>

          <el-submenu index="2">
            <template slot="title" ><span @click="handleClick">Sign out</span></template>
            <div class="title_menu">
           
            </div>
          </el-submenu>

          <el-submenu index="3">
            <template slot="title"><router-link to="/login"> Sign in</router-link></template>
          </el-submenu>

          <div style="width: 50px; float: right" class="dis-blo"></div>
        </el-menu>
      </div>
    </el-header>

    <!-- 搜索框 -->
    <div class="search_bar_container">
      <div class="dis_blo" style="text-align: center; vertical-align: top">
        <div class="search_swi search_swi_text">Search for Images</div>
        <div>
          <el-switch ></el-switch>
        </div>
      </div>

      <div class="dis_blo search_wid">
        <el-input
          placeholder="请输入内容"
          class="input-with-select"
          v-model="Searchword"
        >
          <el-select  placeholder="请选择">
         
          </el-select>
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="SearchClick"
          ></el-button>
        </el-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      Searchword: ''
    }
  },
  methods: {
    handleClick() {
      this.$emit('custom-event');
      // console.log("sign-out");
    },
    SearchClick() {
      let userInput = this.Searchword.trim();
      if (userInput == "" || userInput == undefined){
        alert('搜索词为空！请输入搜索词');
        return;
      }
      this.$emit('SearchClick', userInput);
      
    }
  }
}
</script>