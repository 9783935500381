import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import( '../views/AboutView.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/DetailView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import( '../views/login.vue')
  },
  {
    path: '/404',
    name: 'NotFound',
    component: () => import( '../views/NotFound.vue')
  },
  {
    path: '/SearchDetail',
    name: 'SearchDetail',
    component: () => import( '../views/SearchDetail.vue')
  },
  {
    path: '*',
    redirect: '/404' 
  },
  {
    path: '/rest',
    name: 'rest',
    component: () => import( '../views/Rest.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
